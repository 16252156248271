<template>
  <v-dialog
    v-model="show"
    width="600"
    scrollable
    persistent
    :fullscreen="$vuetify.breakpoint.smAndDown"
    @click:outside="closeDialog"
  >
    <v-card v-if="heat" class="ma-0 pa-0">
      <v-toolbar flat>
        <v-toolbar-title>
          {{ $t("forms.run.title") }}
        </v-toolbar-title>

        <v-spacer></v-spacer>

        <v-btn icon @click="closeDialog">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>

      <v-divider></v-divider>

      <v-card-text class="pa-0">
        <v-container>
          <v-row class="pa-2">
            <v-col cols="12">
              <v-autocomplete
                :label="$t('forms.run.competitor')"
                v-model="selectedEntry"
                :items="entries"
                :item-text="competitorText"
                hide-details
                return-object
              ></v-autocomplete>
            </v-col>
          </v-row>
        </v-container>

        <v-divider></v-divider>

        <v-container>
          <v-row class="pa-2">
            <v-col cols="12">
              <v-form
                v-if="selectedEntry"
                @submit.prevent="submitFormReassignRun"
              >
                <div class="d-flex align-center flex-wrap">
                  <span>
                    {{ $t("forms.run.reassignRun") }}
                  </span>

                  <div class="flex-grow-1 flex-md-grow-0">
                    <v-select
                      v-model="runTransfer.run"
                      :items="runs"
                      :item-text="competitorText"
                      return-object
                      :error-messages="runErrors"
                      class="custom-select mx-2 mt-2"
                    ></v-select>
                  </div>

                  <span>
                    {{
                      $t("forms.run.toCompetitor", {
                        competitor: competitorText(this.selectedEntry)
                      })
                    }}
                  </span>

                  <div class="ml-auto">
                    <v-btn
                      type="submit"
                      :color="iconColor(runTransfer.button.icon)"
                      :loading="runTransfer.isLoading"
                      depressed
                      small
                      fab
                    >
                      <span v-if="runTransfer.button.text">
                        {{ runTransfer.button.text }}
                      </span>
                      <v-icon v-else>
                        {{ runTransfer.button.icon }}
                      </v-icon>
                    </v-btn>
                  </div>
                </div>
              </v-form>
            </v-col>
          </v-row>
        </v-container>

        <v-divider></v-divider>

        <v-container>
          <v-row class="pa-2">
            <v-col cols="12">
              <v-btn-toggle
                v-model="runStatus"
                mandatory
                borderless
                active-class="primary"
                class="d-flex"
                @change="updateRunStatus"
              >
                <v-btn
                  v-for="s in status"
                  :key="s.value"
                  :value="s.value"
                  :loading="s.isLoading"
                  :class="[
                    runStatus === s.value ? 'white--text' : '',
                    'flex-grow-1'
                  ]"
                >
                  {{ s.label }}
                </v-btn>
              </v-btn-toggle>
            </v-col>
          </v-row>
        </v-container>

        <v-divider></v-divider>

        <v-container>
          <v-row
            v-for="(section, index) in sections"
            :key="section.type"
            dense
            class="sectionRow px-2"
          >
            <v-col cols="12" class="mt-2">
              <span class="font-weight-bold">
                {{ section.type | capitalize }}
              </span>
            </v-col>

            <v-col cols="12" sm="5">
              <v-text-field
                :label="$t('forms.run.timeOfDay')"
                v-model.trim="$v.sections.$each.$iter[index].dateTime.$model"
                :disabled="sectionDisabled"
                hint="HH:mm:ss.SSSSS"
                :error-messages="dateTimeError(index)"
                @input="value => dateTimeUpdated(value, index)"
              >
                <template v-slot:append>
                  <v-btn
                    icon
                    small
                    color="primary"
                    @click="openEventTriggerDialog(index)"
                  >
                    <v-icon small>mdi-plus</v-icon>
                  </v-btn>
                </template>
              </v-text-field>
            </v-col>

            <v-col cols="10" sm="5">
              <v-text-field
                v-if="section.type !== 'START'"
                :label="$t('forms.run.runTime')"
                v-model.trim="$v.sections.$each.$iter[index].runTime.$model"
                :disabled="sectionDisabled"
                hint="HH:mm:ss.SSSSS"
                :error-messages="runTimeError(index)"
                @input="
                  value =>
                    !$v.sections.$each.$iter[index].runTime.$invalid &&
                    runTimeUpdated(value, index)
                "
              ></v-text-field>
            </v-col>

            <v-col cols="2" sm="2" class="d-flex align-center justify-end">
              <v-btn
                @click="removeRunTime(index, section)"
                class="mx-1"
                :loading="section.isLoading"
                :disabled="sectionDisabled"
                :color="iconColor(section.deleteIcon, 'error')"
                depressed
                small
                fab
              >
                <v-icon>{{ section.deleteIcon }}</v-icon>
              </v-btn>
            </v-col>

            <EventTriggerDialog
              :ref="`eventTriggerDialog-${index}`"
              :event="event"
              :section="section"
              :sections="sections"
              :position="index"
              @triggerSelected="handleTriggerSelection"
            />
          </v-row>

          <v-row dense>
            <v-col cols="12">
              <v-btn
                @click="updateRunTimes"
                color="primary"
                :loading="runTimes.isLoading"
              >
                {{ $t("forms.run.runTimes.submit") }}
              </v-btn>
            </v-col>
            <v-col cols="12">
              <v-alert
                v-if="runTimes.success"
                class="mt-6"
                dense
                outlined
                type="success"
              >
                {{ runTimes.success }}
              </v-alert>
              <v-alert
                v-if="runTimes.error"
                class="mt-6"
                dense
                outlined
                type="error"
              >
                {{ runTimes.error }}
              </v-alert>
            </v-col>
          </v-row>
        </v-container>

        <v-divider></v-divider>

        <v-container>
          <v-row class="pa-2">
            <v-col cols="6" sm="7">
              <v-text-field
                v-model="timePosting.message"
                counter="15"
                maxlength="15"
                :error-messages="timePostingMessageErrors"
              >
                <template v-slot:label>
                  {{ $t("forms.run.message") }}
                  <span v-if="timePosting.time_s === 0" class="error--text"
                    >*</span
                  >
                </template>
              </v-text-field>
            </v-col>
            <v-col cols="4" sm="3">
              <v-text-field
                v-model.number="timePosting.time_s"
                :label="$t('forms.run.time')"
                type="number"
                suffix="s"
              />
            </v-col>
            <v-col cols="2" sm="2" class="d-flex align-center justify-end">
              <v-btn
                @click="addTimePostingHandler"
                class="mx-1"
                :loading="timePosting.action.isLoading"
                :color="iconColor(timePosting.action.icon)"
                :disabled="selectedEntry && !selectedEntry.run"
                depressed
                small
                fab
              >
                <v-icon>{{ timePosting.action.icon }}</v-icon>
              </v-btn>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col cols="12">
              <v-data-table
                :headers="timePostingsHeaders"
                :items="timePostings"
                disable-sort
                disable-pagination
                hide-default-footer
              >
                <template v-slot:item.time_100ns="{ item }">
                  {{ item.time_100ns / 10000000 }} s
                </template>

                <template v-slot:item.action="{ item }">
                  <v-btn
                    @click="deleteTimePostingHandler(item.id)"
                    :loading="item.isLoading"
                    icon
                  >
                    <v-icon>mdi-delete</v-icon>
                  </v-btn>
                </template>
              </v-data-table>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
/* eslint-disable */
import Vue from "vue";
import formatRunTime from "@/mixins/formatRunTime";
import formatTimestamp from "@/mixins/formatTimestamp";
import cropTime from "@/mixins/cropTime";
import getDeviceBySectionIndex from "@/mixins/getDeviceBySectionIndex";
import {
  required,
  requiredIf,
  minLength,
  maxLength
} from "vuelidate/lib/validators";
import runTime from "@/utils/validators/runTime";
import EventTriggerDialog from "@/components/EventTriggerDialog";
import { mapState, mapGetters, mapActions } from "vuex";

export default {
  name: "CompetitorRunEditDialog",
  props: {
    event: {
      type: Object,
      required: true
    }
  },
  components: {
    EventTriggerDialog
  },
  data() {
    return {
      show: false,
      heatId: null,
      selectedEntry: null,
      isInitialized: false,
      runTransfer: {
        run: null,
        isLoading: false,
        button: {
          text: this.$i18n.t("forms.run.submit"),
          icon: null
        }
      },
      runTimes: {
        isLoading: false,
        error: null,
        success: null
      },
      sections: [],
      runStatus: "OK",
      status: [
        {
          label: this.$i18n.t("forms.run.status.ok"),
          value: "OK",
          isLoading: false
        },
        {
          label: this.$i18n.t("forms.run.status.dns"),
          value: "DNS",
          isLoading: false
        },
        {
          label: this.$i18n.t("forms.run.status.dnf"),
          value: "DNF",
          isLoading: false
        },
        {
          label: this.$i18n.t("forms.run.status.dsq"),
          value: "DSQ",
          isLoading: false
        }
      ],
      timePosting: {
        message: "",
        time_s: 0,
        action: {
          isLoading: false,
          icon: "mdi-plus"
        }
      },
      timePostingsHeaders: [
        {
          value: "time_100ns",
          text: this.$i18n.t("forms.run.time")
        },
        {
          value: "message",
          text: this.$i18n.t("forms.run.message")
        },
        {
          value: "action",
          align: "right"
        }
      ],
      timePostings: [],
      precisions: ["1s", "1/10s", "1/100s", "1/1000s", "1/10000s", "1/100000s"]
    };
  },
  validations() {
    return {
      runTransfer: {
        run: {
          required
        }
      },
      sections: {
        $each: {
          time: {
            timestamp: {
              minLength: minLength(17),
              maxLength: maxLength(17),
              duration: function(value, time) {
                if (value === null) return true;
                const index = this.sections.findIndex(
                  section => section.type === time.type
                );
                if (index > 0 && index < this.sections.length - 1) {
                  // INTERMEDIATE X
                  const beforeSectionTimestamp = this.sections[index - 1].time
                    .timestamp;
                  const afterSectionTimestamp = this.sections[index + 1].time
                    .timestamp;

                  if (beforeSectionTimestamp && afterSectionTimestamp) {
                    return (
                      value > beforeSectionTimestamp &&
                      value < afterSectionTimestamp
                    );
                  } else if (beforeSectionTimestamp) {
                    return value > beforeSectionTimestamp;
                  } else if (afterSectionTimestamp) {
                    return value > afterSectionTimestamp;
                  }
                } else if (index > 0) {
                  // FINISH
                  const beforeSectionTimestamp = this.sections[index - 1].time
                    .timestamp;

                  if (beforeSectionTimestamp) {
                    return value > beforeSectionTimestamp && value;
                  }
                }
                return true;
              }
            }
          },
          dateTime: {
            format: function(value, section) {
              return section.isDateTimeValid;
            }
          },
          runTime: {
            format: runTime
          }
        }
      },
      timePosting: {
        message: {
          required: requiredIf(function() {
            return this.timePosting.time_s === 0;
          })
        }
      }
    };
  },
  computed: {
    ...mapState({
      user: state => state.user.user,
      eventTrigger: state => state.events.selectedItemTrigger,
      autoUpdate: state => state.events.autoUpdate
    }),
    ...mapGetters({
      getCompetitorById: "events/getCompetitorById",
      getDeviceById: "devices/getItemById"
    }),
    heat() {
      if (this.heatId) {
        const heatIndex = this.event.heats.findIndex(
          el => el.id === this.heatId
        );
        if (heatIndex !== -1) {
          return this.event.heats[heatIndex];
        }
      }

      return null;
    },
    startDevice() {
      return this.getDeviceBySectionIndex(0);
    },
    entries() {
      if (this.heat) {
        return this.heat.competitors.reduce((re, competitorId) => {
          const entry = {
            competitor: this.getCompetitorById(competitorId)
          };
          const heatCycleIndex = this.heat.heatCycle.entries.findIndex(
            el => el.competitorId === competitorId
          );

          if (heatCycleIndex !== -1) {
            entry.run = this.heat.heatCycle.entries[heatCycleIndex];
          }

          re.push(entry);

          return re;
        }, []);
      }

      return [];
    },
    runErrors() {
      const errors = [];
      if (!this.$v.runTransfer.run.$dirty) return errors;
      !this.$v.runTransfer.run.required &&
        errors.push(this.$i18n.t("forms.run.errors.run.required"));
      return errors;
    },
    timePostingMessageErrors() {
      const errors = [];
      if (!this.$v.timePosting.message.$dirty) return errors;
      !this.$v.timePosting.message.required &&
        errors.push(this.$i18n.t("forms.timePosting.errors.message.required"));
      return errors;
    },
    start() {
      return this.sections[0].timestamp;
    },
    finish() {
      return this.sections[this.sections.length - 1].timestamp;
    },
    sectionDisabled() {
      return this.runStatus !== "OK";
    },
    startListMap() {
      if (!this.heat) return new Map();

      return new Map(
        this.heat.competitors.map((competitorId, index) => [
          competitorId,
          index
        ])
      );
    },
    runs() {
      if (this.heat && this.selectedEntry) {
        return this.heat.heatCycle.entries
          .filter(el => {
            if (this.selectedEntry.run) {
              return el.runId !== this.selectedEntry.run.runId;
            }

            return true;
          })
          .map(el => ({
            competitor: this.getCompetitorById(el.competitorId),
            ...el
          }))
          .sort((a, b) => {
            const indexA = this.startListMap.get(a.competitorId);
            const indexB = this.startListMap.get(b.competitorId);

            if (indexA !== undefined && indexB !== undefined) {
              return indexA - indexB;
            }

            if (indexA !== undefined) return -1;
            if (indexB !== undefined) return 1;

            return 0;
          });
      }

      return [];
    },
    precision() {
      const precision = this.precisions.findIndex(
        el => el === this.event.setup.precision
      );
      return precision !== -1 ? precision : 2;
    }
  },
  mixins: [formatRunTime, formatTimestamp, cropTime, getDeviceBySectionIndex],
  filters: {
    capitalize: function(value) {
      if (!value) return "";
      value = value.toString().toLowerCase();
      return value.charAt(0).toUpperCase() + value.slice(1);
    }
  },
  watch: {
    entries(newEntries) {
      if (this.selectedEntry) {
        const selectedEntryIndex = newEntries.findIndex(
          el => el.competitor.id === this.selectedEntry.competitor.id
        );
        let entry = {
          competitor: this.getCompetitorById(this.selectedEntry.competitor.id)
        };

        if (selectedEntryIndex !== -1) {
          entry = newEntries[selectedEntryIndex];
        }

        this.selectedEntry = entry;
      }
    },
    selectedEntry(newSelectedEntry, oldSelectedEntry) {
      if (
        !oldSelectedEntry ||
        newSelectedEntry.competitor.id !== oldSelectedEntry.competitor.id
      ) {
        this.initData();
        this.resetRunTransferForm();
        this.resetRunTimeForm();
      }
    }
  },
  methods: {
    ...mapActions({
      updateRunTimesByCompetitor: "events/updateRunTimesByCompetitor",
      removeRunTimeByCompetitor: "events/removeRunTimeByCompetitor",
      reassignRun: "events/reassignRun",
      cancelRunByCompetitor: "events/cancelRunByCompetitor",
      resetCancellationByCompetitor: "events/resetCancellationByCompetitor",
      createTimePostingEntry: "events/createTimePostingEntry",
      deleteTimePostingEntry: "events/deleteTimePostingEntry",
      restartRunByCompetitor: "events/restartRunByCompetitor"
    }),
    openDialog(heatId, competitorId) {
      this.heatId = heatId;

      let entry = {
        competitor: this.getCompetitorById(competitorId)
      };

      const entriesIndex = this.entries.findIndex(
        el => el.competitor.id === competitorId
      );
      if (entriesIndex !== -1) {
        entry = this.entries[entriesIndex];
      }

      this.selectedEntry = entry;
      this.initData();
      this.resetRunTransferForm();
      this.resetRunTimeForm();
      this.show = true;
    },
    closeDialog() {
      if (this.$v.$anyDirty) {
        if (confirm(this.$i18n.t("hints.closeConfirmation"))) {
          this.show = false;
        }
      } else {
        this.show = false;
      }
    },
    openEventTriggerDialog(index) {
      this.$refs[`eventTriggerDialog-${index}`][0].openDialog();
    },
    submitFormReassignRun() {
      this.$v.runTransfer.$touch();

      if (!this.$v.runTransfer.$invalid) {
        this.runTransfer.isLoading = true;

        const payload = {
          eventId: this.event.id,
          heatId: this.heat.id,
          runId: this.runTransfer.run.runId,
          data: {
            value: this.selectedEntry.competitor.id
          }
        };

        this.reassignRun(payload)
          .then(response => {
            this.runTransfer.isLoading = false;

            this.runTransfer.button.icon = "mdi-check";
            this.runTransfer.button.text = null;

            this.initData();
            this.runTransfer.run = null;

            setTimeout(() => {
              this.runTransfer.button.icon = null;
              this.runTransfer.button.text = this.$i18n.t("forms.run.submit");
            }, 2000);
          })
          .catch(response => {
            this.runTransfer.isLoading = false;

            this.runTransfer.button.icon = "mdi-close";
            this.runTransfer.button.text = null;

            setTimeout(() => {
              this.runTransfer.button.icon = null;
              this.runTransfer.button.text = this.$i18n.t("forms.run.submit");
            }, 2000);
          });
      }
    },
    dateTimeError(sectionIndex) {
      const errors = [];
      if (!this.$v.sections.$each.$iter[sectionIndex].dateTime.$dirty)
        return errors;
      !this.$v.sections.$each.$iter[sectionIndex].dateTime.format &&
        errors.push(this.$i18n.t("forms.run.errors.timeOfDay.format"));
      this.$v.sections.$each.$iter[sectionIndex].dateTime.$dirty &&
        errors.push(this.$i18n.t("forms.run.errors.timeOfDay.dirty"));

      if (errors.length > 0) {
        return errors[0];
      }

      return null;
    },
    runTimeError(sectionIndex) {
      const errors = [];
      if (!this.$v.sections.$each.$iter[sectionIndex].runTime.$dirty)
        return errors;
      !this.$v.sections.$each.$iter[sectionIndex].runTime.format &&
        errors.push(this.$i18n.t("forms.run.errors.runTime.format"));
      !this.$v.sections.$each.$iter[sectionIndex].time.timestamp.duration &&
        errors.push(this.$i18n.t("forms.run.errors.runTime.duration"));
      this.$v.sections.$each.$iter[sectionIndex].runTime.$dirty &&
        errors.push(this.$i18n.t("forms.run.errors.runTime.dirty"));

      if (errors.length > 0) {
        return errors[0];
      }

      return null;
    },
    competitorText(item) {
      return `${item.competitor.startNumber} - ${item.competitor.userData.lastName} ${item.competitor.userData.firstName}`;
    },
    iconColor(icon, defaultColor = "primary") {
      switch (String(icon)) {
        case "mdi-check":
          return "success";
        case "mdi-close":
          return "error";
        default:
          return defaultColor;
      }
    },
    initData() {
      this.$v.$reset();

      this.setStatus();
      this.setSections();
      this.setTimePostings();
    },
    resetRunTransferForm() {
      this.runTransfer = {
        run: null,
        isLoading: false,
        button: {
          text: this.$i18n.t("forms.run.submit"),
          icon: null
        }
      };
    },
    resetRunTimeForm() {
      this.runTimes = {
        isLoading: false,
        error: null,
        success: null
      };
    },
    setTimePostings() {
      if (
        this.selectedEntry &&
        this.selectedEntry.run &&
        this.selectedEntry.run.data &&
        this.selectedEntry.run.data.timePostings
      ) {
        this.timePostings = this.selectedEntry.run.data.timePostings.map(
          el => ({
            ...el,
            isLoading: false
          })
        );
      } else {
        this.timePostings = new Array();
      }
    },
    setStatus() {
      if (
        this.selectedEntry &&
        this.selectedEntry.run &&
        this.selectedEntry.run.data
      ) {
        this.runStatus =
          this.selectedEntry.run.data.cancellation === "UNDEFINED"
            ? "OK"
            : this.selectedEntry.run.data.cancellation;
      } else {
        this.runStatus = "OK";
      }
    },
    setSections() {
      const sections = this.event.setup.sections;
      const sectionsKeys = sections.map(section => section.type);

      sectionsKeys.map((key, index) => this.setSection(index, key));
    },
    setSection(index, key, keepState = false) {
      let section = {
        type: key,
        time: {
          type: key,
          timestamp: null,
          timeOffset: null
        },
        dateTime: null,
        isDateTimeValid: true,
        runTime: null,
        deleteIcon: keepState ? this.sections[index].deleteIcon : "mdi-delete",
        isLoading: keepState ? this.sections[index].isLoading : false
      };

      if (
        this.selectedEntry &&
        this.selectedEntry.run &&
        this.selectedEntry.run.data &&
        this.selectedEntry.run.data.sections[key] &&
        this.selectedEntry.run.data.sections[key].timestamp_100ns
      ) {
        section.time.timestamp = this.selectedEntry.run.data.sections[
          key
        ].timestamp_100ns;
        section.time.timeOffset = this.selectedEntry.run.data.sections[
          key
        ].timeOffset_m;
        section.dateTime = this.formatTimestampToTime(
          this.selectedEntry.run.data.sections[key].timestamp_100ns,
          this.selectedEntry.run.data.sections[key].timeOffset_m
        );

        const timestamp = this.addOffsetToTimestamp(
          this.selectedEntry.run.data.sections[key].timestamp_100ns,
          this.selectedEntry.run.data.sections[key].timeOffset_m
        );

        if (this.selectedEntry.run.data.sections["START"].timestamp_100ns) {
          const startTimestamp = this.addOffsetToTimestamp(
            this.selectedEntry.run.data.sections["START"].timestamp_100ns,
            this.selectedEntry.run.data.sections["START"].timeOffset_m
          );

          section.runTime = this.cropTime(
            this.formatRunTime(timestamp - startTimestamp, this.precision)
          );
        }
      }

      Vue.set(this.sections, index, section);
    },
    setStartSectionFallback() {
      const fallbackDateTime = "00:00:00.00000";
      const timestamp = this.formatTimeToTimestamp(fallbackDateTime);
      const offset = this.startDevice
        ? this.startDevice.deviceComponents.TimingInfo.timeOffsetUTC
        : null;
      Vue.set(
        this.sections[0].time,
        "timestamp",
        offset ? this.subtractOffsetFromTimestamp(timestamp, offset) : timestamp
      );
      Vue.set(this.sections[0].time, "timeOffset", offset);
      Vue.set(
        this.sections[0],
        "dateTime",
        this.formatTimestampToTime(timestamp)
      );
    },
    dateTimeUpdated(value, index) {
      this.$v.sections.$each.$iter[index].dateTime.$touch();
      Vue.set(this.sections[index], "isDateTimeValid", true);
      if (this.sections[index].time.timeOffset === null) {
        const sectionDevice = this.getDeviceBySectionIndex(index);

        if (sectionDevice) {
          Vue.set(
            this.sections[index].time,
            "timeOffset",
            sectionDevice.deviceComponents.TimingInfo.timeOffsetUTC
          );
        } else if (this.startDevice) {
          Vue.set(
            this.sections[index].time,
            "timeOffset",
            this.startDevice.deviceComponents.TimingInfo.timeOffsetUTC
          );
        }
      }

      const timestamp = this.formatTimeToTimestamp(value);
      if (timestamp) {
        const timestampWithoutOffset = this.subtractOffsetFromTimestamp(
          timestamp,
          this.sections[index].time.timeOffset
        );
        Vue.set(this.sections[index].time, "timestamp", timestampWithoutOffset);
        if (this.sections[0].time.timestamp === null) {
          this.setStartSectionFallback();
        }
        const startTimestampWithOffset = this.addOffsetToTimestamp(
          this.sections[0].time.timestamp,
          this.sections[0].time.timeOffset
        );
        if (index === 0) {
          this.sections.slice(1).map((section, index) => {
            if (section.time.timestamp) {
              Vue.set(
                this.sections[index + 1],
                "runTime",
                this.cropTime(
                  this.formatRunTime(
                    this.addOffsetToTimestamp(
                      section.time.timestamp,
                      section.time.timeOffset
                    ) - startTimestampWithOffset,
                    this.precision
                  )
                )
              );
            }
          });
        } else {
          Vue.set(
            this.sections[index],
            "runTime",
            this.cropTime(
              this.formatRunTime(
                timestamp - startTimestampWithOffset,
                this.precision
              )
            )
          );
        }
      } else if (value) {
        Vue.set(this.sections[index], "isDateTimeValid", false);
      }
    },
    runTimeUpdated(value, index) {
      this.$v.sections.$each.$iter[index].runTime.$touch();
      if (this.sections[0].time.timestamp === null) {
        this.setStartSectionFallback();
      }
      const startTimestampWithOffset = this.addOffsetToTimestamp(
        this.sections[0].time.timestamp,
        this.sections[0].time.timeOffset
      );
      const runTimeTimestamp = this.formatRunTimeToTimestamp(value);

      if (this.sections[index].time.timeOffset === null) {
        const sectionDevice = this.getDeviceBySectionIndex(index);

        if (sectionDevice) {
          Vue.set(
            this.sections[index].time,
            "timeOffset",
            sectionDevice.deviceComponents.TimingInfo.timeOffsetUTC
          );
        } else if (this.startDevice) {
          Vue.set(
            this.sections[index].time,
            "timeOffset",
            this.startDevice.deviceComponents.TimingInfo.timeOffsetUTC
          );
        }
      }

      const timestamp = startTimestampWithOffset + runTimeTimestamp;
      const timestampWithoutOffset = this.subtractOffsetFromTimestamp(
        timestamp,
        this.sections[index].time.timeOffset
      );
      const dateTime = this.formatTimestampToTime(timestamp);

      Vue.set(this.sections[index].time, "timestamp", timestampWithoutOffset);
      Vue.set(this.sections[index], "dateTime", dateTime);
      if (dateTime) {
        Vue.set(this.sections[index], "isDateTimeValid", true);
      }
    },
    formatTimestampToTime(timestamp, offset = 0) {
      if (!timestamp) return null;

      return this.formatTimestamp_100ns(timestamp, offset, false);
    },
    formatRunTimeToTimestamp(value) {
      if (!value) return null;

      const valueSplit = value.split(new RegExp("[.,]"));

      const left = valueSplit[0];
      const leftSplit = left.split(":").reverse();
      const seconds =
        leftSplit[0] === undefined ? 0 : Number(leftSplit[0]) * 10000000;
      const minutes =
        leftSplit[1] === undefined ? 0 : Number(leftSplit[1]) * 600000000;
      const hours =
        leftSplit[2] === undefined ? 0 : Number(leftSplit[2]) * 36000000000;

      const right = valueSplit[1] || "";
      const remainder = Number(right.padEnd(5, "0")) * 100;

      return hours + minutes + seconds + remainder;
    },
    formatTimeToTimestamp(value) {
      if (!value) return null;

      const heatDate = new Date(this.heat.startTime);

      switch (true) {
        case /^((2[0-3])|([0-1][0-9])|([0-9])):?$/.test(value): {
          // 1 > 1:00:00.00000
          // 12 > 12:00:00.00000
          // 1: > 1:00:00.00000
          // 12: > 12:00:00.00000
          return (
            Date.UTC(
              heatDate.getUTCFullYear(),
              heatDate.getUTCMonth(),
              heatDate.getUTCDate(),
              parseInt(value.slice(0, 2)),
              0,
              0
            ) * 10000
          );
        }
        case /^((2[0-3])|([0-1][0-9])|([0-9])):([0-5]|([0-5][0-9]:?))$/.test(
          value
        ): {
          // 1:00 > 1:00:00.00000
          // 12:00 > 12:00:00.00000
          // 1:00: > 1:00:00.00000
          // 12:00: > 12:00:00.00000
          const valueSplit = value.split(":");
          return (
            Date.UTC(
              heatDate.getUTCFullYear(),
              heatDate.getUTCMonth(),
              heatDate.getUTCDate(),
              parseInt(valueSplit[0]),
              parseInt(valueSplit[1].slice(0, 2).padEnd(2, "0")),
              0
            ) * 10000
          );
        }
        case /^((2[0-3])|([0-1][0-9])|([0-9])):[0-5][0-9]:([0-5]|([0-5][0-9]:?))$/.test(
          value
        ): {
          // 1:00:00 > 1:00:00.00000
          // 12:00:00 > 12:00:00.00000
          const valueSplit = value.split(":");
          return (
            Date.UTC(
              heatDate.getUTCFullYear(),
              heatDate.getUTCMonth(),
              heatDate.getUTCDate(),
              parseInt(valueSplit[0]),
              parseInt(valueSplit[1]),
              parseInt(valueSplit[2].padEnd(2, "0"))
            ) * 10000
          );
        }
        case /^((2[0-3])|([0-1][0-9])|([0-9])):[0-5][0-9]:[0-5][0-9][.,][0-9]{0,5}$/.test(
          value
        ): {
          // 1:00:00. > 1:00:00.00000
          // 12:00:00. > 12:00:00.00000
          // 1:00:00.1 > 1:00:00.10000
          // 12:00:00.1 > 12:00:00.10000
          const valueSplit = value.split(new RegExp("[:.,]"));
          return (
            Date.UTC(
              heatDate.getUTCFullYear(),
              heatDate.getUTCMonth(),
              heatDate.getUTCDate(),
              parseInt(valueSplit[0]),
              parseInt(valueSplit[1]),
              parseInt(valueSplit[2])
            ) *
              10000 +
            parseInt(valueSplit[3].padEnd(5, "0")) * 100
          );
        }
        case /^((:?[0-5][0-9])|[0-9]):[0-5][0-9][.,][0-9]{0,5}$/.test(value): {
          // 1:00. > 00:01:00.00000
          // 12:00. > 00:12:00.00000
          // :12:00. > 00:12:00.00000
          const valueSplit = value.split(new RegExp("[:.,]")).reverse();
          return (
            Date.UTC(
              heatDate.getUTCFullYear(),
              heatDate.getUTCMonth(),
              heatDate.getUTCDate(),
              parseInt(valueSplit[2]),
              parseInt(valueSplit[1]),
              0
            ) *
              10000 +
            parseInt(valueSplit[0].padEnd(5, "0")) * 100
          );
        }
        case /^((:?[0-5][0-9])|[0-9])[.,][0-9]{0,5}$/.test(value): {
          // 1. > 00:00:01.00000
          // 12. > 00:00:12.00000
          // :12. > 00:00:12.00000
          const valueSplit = value.split(new RegExp("[:.,]")).reverse();
          return (
            Date.UTC(
              heatDate.getUTCFullYear(),
              heatDate.getUTCMonth(),
              heatDate.getUTCDate(),
              0,
              0,
              parseInt(valueSplit[1])
            ) *
              10000 +
            parseInt(valueSplit[0].padEnd(5, "0")) * 100
          );
        }
        case /^[.,][0-9]{0,5}$/.test(value): {
          // . > 00:00:00.00000
          const valueSplit = value.split(new RegExp("[.,]")).reverse();
          return (
            Date.UTC(
              heatDate.getUTCFullYear(),
              heatDate.getUTCMonth(),
              heatDate.getUTCDate(),
              0,
              0,
              0
            ) *
              10000 +
            parseInt(valueSplit[0].padEnd(5, "0")) * 100
          );
        }
        default:
          return null;
      }
    },
    updateRunTimes() {
      if (!this.$v.sections.$invalid) {
        this.runTimes.isLoading = true;
        this.runTimes.error = null;
        this.runTimes.success = null;

        const payload = {
          userId: this.user.id,
          eventId: this.event.id,
          heatId: this.heat.id,
          competitorId: this.selectedEntry.competitor.id,
          data: this.sections
            .filter(section => section.time.timestamp !== null)
            .map(section => ({
              section: section.type,
              timestamp_100ns: this.addOffsetToTimestamp(
                section.time.timestamp,
                section.time.timeOffset
              )
            }))
        };

        this.updateRunTimesByCompetitor(payload)
          .then(() => {
            this.runTimes.isLoading = false;
            this.runTimes.success = this.$i18n.t("forms.run.runTimes.success");
            this.$v.sections.$reset();
          })
          .catch(() => {
            this.runTimes.isLoading = false;
            this.runTimes.error = this.$i18n.t("forms.run.runTimes.failed");
          });
      }
    },
    updateRunStatus(value) {
      const statusIndex = this.status.findIndex(
        status => status.value === value
      );
      Vue.set(this.status[statusIndex], "isLoading", true);

      let promise;

      if (value === "OK") {
        const payload = {
          eventId: this.event.id,
          heatId: this.heat.id,
          runId: this.selectedEntry.run.runId
        };

        promise = this.resetCancellationByCompetitor(payload);
      } else {
        const payload = {
          eventId: this.event.id,
          heatId: this.heat.id,
          competitorId: this.selectedEntry.competitor.id,
          data: {
            message: "",
            reason: value
          }
        };

        promise = this.cancelRunByCompetitor(payload);
      }

      promise
        .then(response => {
          if (response.data.status !== 0) {
            return Promise.reject(response);
          }

          Vue.set(this.status[statusIndex], "isLoading", false);
        })
        .catch(() => {
          Vue.set(this.status[statusIndex], "isLoading", false);
          this.setStatus();
        });
    },
    removeRunTime(index, section) {
      Vue.set(this.sections[index], "isLoading", true);

      if (section.type === "START") {
        const payload = {
          eventId: this.event.id,
          heatId: this.heat.id,
          runId: this.selectedEntry.run.runId,
          includeStartgroup: false
        };

        confirm(this.$i18n.t("forms.run.restartRunConfirmation")) &&
          this.restartRunByCompetitor(payload)
            .then(() => {
              this.initData();
              this.resetRunTransferForm();
              this.resetRunTimeForm();

              Vue.set(this.sections[index], "deleteIcon", "mdi-check");
              setTimeout(() => {
                Vue.set(this.sections[index], "deleteIcon", "mdi-delete");
              }, 2000);
              this.setSection(index, section.type, true);
              this.$v.sections.$each.$iter[index].$reset();
              Vue.set(this.sections[index], "isLoading", false);
            })
            .catch(() => {
              Vue.set(this.sections[index], "deleteIcon", "mdi-close");
              setTimeout(() => {
                Vue.set(this.sections[index], "deleteIcon", "mdi-delete");
              }, 2000);
              Vue.set(this.sections[index], "isLoading", false);
            });
      } else {
        const payload = {
          eventId: this.event.id,
          heatId: this.heat.id,
          runId: this.selectedEntry.run.runId,
          sectionId: section.type
        };

        this.removeRunTimeByCompetitor(payload)
          .then(() => {
            Vue.set(this.sections[index], "deleteIcon", "mdi-check");
            setTimeout(() => {
              Vue.set(this.sections[index], "deleteIcon", "mdi-delete");
            }, 2000);
            this.setSection(index, section.type, true);
            this.$v.sections.$each.$iter[index].$reset();
            Vue.set(this.sections[index], "isLoading", false);
          })
          .catch(() => {
            Vue.set(this.sections[index], "deleteIcon", "mdi-close");
            setTimeout(() => {
              Vue.set(this.sections[index], "deleteIcon", "mdi-delete");
            }, 2000);
            Vue.set(this.sections[index], "isLoading", false);
          });
      }
    },
    handleTriggerSelection(sectionIndex, selectedTrigger) {
      if (
        sectionIndex >= 0 &&
        sectionIndex < this.sections.length &&
        selectedTrigger !== null
      ) {
        Vue.set(
          this.sections[sectionIndex].time,
          "timestamp",
          selectedTrigger.timestamp
        );
        Vue.set(
          this.sections[sectionIndex].time,
          "timeOffset",
          selectedTrigger.timeOffset
        );
        Vue.set(
          this.sections[sectionIndex],
          "dateTime",
          this.formatTimestampToTime(
            selectedTrigger.timestamp,
            selectedTrigger.timeOffset ? selectedTrigger.timeOffset : null
          )
        );
        if (this.sections[0].time.timestamp === null) {
          this.setStartSectionFallback();
        }
        Vue.set(
          this.sections[sectionIndex],
          "runTime",
          this.cropTime(
            this.formatRunTime(
              selectedTrigger.timestamp - this.sections[0].time.timestamp,
              this.precision
            )
          )
        );
      }
    },
    addOffsetToTimestamp(timestamp, offset) {
      offset = (offset * -1) / 60;
      return timestamp - 36000000000 * offset;
    },
    subtractOffsetFromTimestamp(timestamp, offset) {
      offset = offset / 60;
      return timestamp - 36000000000 * offset;
    },
    addTimePostingHandler() {
      this.$v.timePosting.$touch();

      if (!this.$v.timePosting.$invalid) {
        this.timePosting.action.isLoading = true;

        const payload = {
          eventId: this.event.id,
          heatId: this.heat.id,
          runId: this.selectedEntry.run.runId,
          data: {
            type: this.timePosting.time_s >= 0 ? "REWARD" : "PENALTY",
            time_100ns: this.timePosting.time_s * 10000000,
            message: this.timePosting.message
          }
        };

        this.createTimePostingEntry(payload)
          .then(() => {
            this.setTimePostings();
            this.timePosting.action.isLoading = false;
            this.timePosting.action.icon = "mdi-check";
            setTimeout(() => {
              this.timePosting.action.icon = "mdi-plus";
            }, 2000);
            this.$v.timePosting.$reset();
          })
          .catch(() => {
            this.timePosting.action.isLoading = false;
            this.timePosting.action.icon = "mdi-close";
            setTimeout(() => {
              this.timePosting.action.icon = "mdi-plus";
            }, 2000);
          });
      }
    },
    deleteTimePostingHandler(timePostingId) {
      const index = this.timePostings.findIndex(el => el.id === timePostingId);
      Vue.set(this.timePostings[index], "isLoading", true);

      const payload = {
        eventId: this.event.id,
        heatId: this.heat.id,
        runId: this.selectedEntry.run.runId,
        timePostingId
      };

      this.deleteTimePostingEntry(payload)
        .then(() => {
          this.setTimePostings();
        })
        .catch(() => {
          Vue.set(this.timePostings[index], "isLoading", false);
        });
    }
  }
};
</script>
<style>
.custom-select .v-select__selections {
  min-width: 100px;
}
.custom-select .v-select__selections input {
  width: 0;
}
.sectionRow:nth-child(even) {
  background: #f7f7f7;
}
</style>
